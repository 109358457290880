.button {
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #24cc7f !important;
  color: white !important;
  border: 1px solid #24cc7f !important;
  font-family: 'Roboto', sans-serif;
  height: 40px;
  border-radius: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
