.card {
  border-radius: 10px;
  color: white;
}
.text {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #2c2c2c;
}

.table {
}

.button {
  display: flex;
  /*justify-content: space-between;*/
  justify-content: center;
  align-content: center;
  background-color: #24cc7f !important;
  color: white;
  border: 1px solid #24cc7f !important;
  font-family: 'Roboto', sans-serif;
  width: 206px;
  height: 40px;
  border-radius: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
