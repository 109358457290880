.input {
  border-radius: 4px;
  border: 1px solid #b6c6e6;
  width: 292px;
  height: 40px;
  padding-left: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.icon {
  padding-bottom: 20px;
}
