.card {
  border-radius: 10px;
  color: white;
}
.titleCard {
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #2c2c2c;
}
.table {
  border-radius: 10px;
  border-color: #b6c6e5;
  width: 528px;
}

.halfTable {
  border-radius: 10px;
  border-color: #b6c6e5;
}

.titleText {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #2c2c2c;
}
.textCompanyTitle {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #2c2c2c;
}
.text {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #2c2c2c;
}
.description {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #969696;
}
.info {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #b6c6e5;
}
.row {
  width: 100%;
  display: flex;
}

.inputPhone {
  border-radius: 4px;
  border: 1px solid #b6c6e6;
  width: 292px;
  height: 40px;
  padding-left: 15px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.padding {
  padding-right: 30px;
  max-width: 320px;
}

.inputDate {
  border: 1px solid transparent !important;
  padding-left: 0;
}
.button {
  display: flex;
  /*justify-content: space-between;*/
  justify-content: center;
  align-content: center;
  background-color: #24cc7f !important;
  color: white;
  border: 1px solid #24cc7f !important;
  font-family: 'Roboto', sans-serif;
  width: 206px;
  height: 40px;
  border-radius: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}
.cancel {
  display: flex;
  justify-content: center;
  align-content: center;
  border: 1px solid #24cc7f !important;
  font-family: 'Roboto', sans-serif;
  height: 40px;
  border-radius: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-left: 30px;
}
.payoutTableHeaderElement {
  width: 12vw;
  border: #b6c6e5 1px solid;
  border-radius: 4px;
  margin-right: 22px;
}
.emptyTable {
  margin: 100px auto;
  text-align: center;
  width: 600px;
}
