.header {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  z-index: 100;
  height: 53px;
  position: fixed;
  width: 100vw;
}

.headerText {
  padding-top: 10px;
  color: #24cc7f !important;
  cursor: pointer;
}

.iconHeaderPadding {
  margin-right: 20px;
}

.name {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #2c2c2c;
}

.job {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  color: #8387a6;
}

.span {
  background-color: red;
}

.item {
  display: flex;
  justify-content: space-between;
}

.content {
  padding: 80px 80px 80px 280px;
  overflow: scroll;
}

.titlePercent {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  color: #6f7b92;
  margin: 0 0 0 22px;
  font-weight: 400;
}

.progress {
  margin-top: 10px;
}

.steps {
  margin-left: 15px;
}

.step {
  font-weight: 400;
  width: 210px;
  cursor: pointer;
}

.stepTitle {
  text-decoration: underline;
}

.stepTitleChecked {
  cursor: default !important;
  opacity: 0.5;
}

.menu {
  margin-top: -9px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 200px;
}
.menuItem {
  margin-top: 5px;
  font-weight: bold;
}
