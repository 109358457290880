.button {
  display: flex;
  justify-content: center;
  border: 1px solid #b6c6e5 !important;
  font-family: 'Roboto', sans-serif;
  height: 26px;
  border-radius: 4px;
  align-items: center;
  width: 40px;
  margin: 0 4px;
}
