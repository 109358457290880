.form {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-self: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.card {
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: flex-start;
  height: 492px;
  border: 1px solid white;
  border-radius: 10px;
}
.item {
  display: flex;
  align-items: start;
  justify-content: start;
  color: #2c2c2c;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 0;
}

.link {
  color: #24cc7f;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 20px;
}

.title {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 700;
}
.line {
  width: 292px;
  height: 1px;
  background-color: #bfcde8;
  margin-top: 20px;
  margin-bottom: 20px;
}

.button {
  display: flex;
  /*justify-content: space-between;*/
  justify-content: center;
  align-content: center;
  background-color: #24cc7f !important;
  color: white;
  border: 1px solid #24cc7f !important;
  font-family: 'Roboto', sans-serif;
  width: 292px;
  height: 40px;
  border-radius: 4px;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-top: 16px;
}
.simpleText {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #2c2c2c;
}
